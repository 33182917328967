import React, { useEffect, useState } from 'react'
import '../clock.css'

const Clock = () => {
    const [hrPosition, setHrPosition] = useState(0)
    const [minPosition, setMinPosition] = useState(0)
    const [secPosition, setSecPosition] = useState(0)

    const run_the_clock = () => {
        let time = new Date()
        let hr = time.getHours();
        let min = time.getMinutes();
        let sec = time.getSeconds();

        let hrPosition = hr * 360 / 12 + ((min * 360 / 60) / 12);
        let minPosition = (min * 360 / 60) + (sec * 360 / 60) / 60;
        let secPosition = sec * 360 / 60;

        setHrPosition("rotate(" + hrPosition + "deg")
        setMinPosition("rotate(" + minPosition + "deg")
        setSecPosition("rotate(" + secPosition + "deg")
    }

    useEffect(() => {
        const interval = setInterval(() => {
            run_the_clock()
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="clockbox">
            <svg id="clock" width="100%" height="100%" viewBox="0 0 600 600">
                <g id="face">
                    <circle className="circle" cx="300" cy="300" r="253.9" />
                    <path className="hour-marks" d="M300.5 94V61M506 300.5h32M300.5 506v33M94 300.5H60M411.3 107.8l7.9-13.8M493 190.2l13-7.4M492.1 411.4l16.5 9.5M411 492.3l8.9 15.3M189 492.3l-9.2 15.9M107.7 411L93 419.5M107.5 189.3l-17.1-9.9M188.1 108.2l-9-15.6" />
                    <circle className="mid-circle" cx="300" cy="300" r="16.2" />
                </g>
                <g id="hour" style={{ transform: hrPosition }}>
                    <path className="hour-arm" d="M300.5 298V142" />
                    <circle className="sizing-box" cx="300" cy="300" r="253.9" />
                </g>
                <g id="minute" style={{ transform: minPosition }}>
                    <path className="minute-arm" d="M300.5 298V67" />
                    <circle className="sizing-box" cx="300" cy="300" r="253.9" />
                </g>
                <g id="second" style={{ transform: secPosition }}>
                    <path className="second-arm" d="M300.5 350V55" />
                    <circle className="sizing-box" cx="300" cy="300" r="253.9" />
                </g>
            </svg>
        </div>
    )
}

export default Clock