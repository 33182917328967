import { LinearProgress } from '@material-ui/core'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import Menu from './Menu'
import Page1 from './Page1'
import Page2 from './Page2'
import TimeCircular from './TimeCircular'

const Home = () => {
    const location = localStorage.getItem("location")
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [logo, setLogo] = useState()
    const [counter, setCounter] = useState(0)


    const handleCount = () => {
        if(counter == pages.length){
            setCounter(0)
        } else {
            setCounter(counter + 1)
        }
    }

    const pages = [
        <Page1 data={data} handleCount={handleCount} logo={logo}/>,
        <Page2 data={data} handleCount={handleCount} logo={logo}/>
    ]
   

    const getData = () => {
        Axios.get('https://admin.sigzentrum.ch/sig-tv/items/tvs/' + location).then(res => {
            setData(res.data)
            getLogo(res.data.data.logo)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (location) {
            getData()
        }

        //get all 60 seconds data from backend server
        setInterval(() => {
            getData()
        }, 60000)
    }, [])

    const getLogo = async (logo) => {
        let file = await Axios.get('https://admin.sigzentrum.ch/sig-tv/files/' + logo)
        setLogo(file.data.data.data.full_url)
    }


    if (counter === pages.length) {
        setCounter(0);
    }

    /* if no locations has found in the lcoal storage, open Menu */
    if (!location) {
        return <Menu />
    }

    if (loading) {
        return <LinearProgress />
    }

    /* Main Page */
    return (
        <div>
            {pages[counter]}
        </div>
    )
}

export default Home