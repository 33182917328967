import React, { useEffect, useState } from 'react'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Axios from 'axios';
import { LinearProgress } from '@material-ui/core';

const Menu = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)
    const [cities, setCities] = useState([])
    const [selectedCity, setSelectedCity] = useState()

    const handleClick = () => {
        localStorage.setItem('location', selected)
        localStorage.setItem('prayerTimeCity', selectedCity)
        window.location.reload()
    }

    const getData = () => {
        Axios.get('https://admin.sigzentrum.ch/sig-tv/items/tvs').then(res => {
            setData(res.data.data)
            setIsLoading(false)
        })
        Axios.get('https://live.igmgapp.org:8091/api/Calendar/GetPrayerTimesCities?ofCountry=ch&prefLocale=ch').then(res => {
            setCities(res.data.list)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    if (isLoading) {
        return <LinearProgress />
    }

    return (
        <div className="home">
            <h3>TV auswählen</h3>
            <Select
                onChange={e => {
                    setSelected(e.target.value)
                }}
            >
                {
                    data.map(d => {
                        return <MenuItem key={d.id} value={d.id}>{d.name + ' - ' + d.location}</MenuItem>
                    })
                }
            </Select>

            <h3>Ort für Gebetszeiten</h3>
            <Select
                onChange={e => {
                    setSelectedCity(e.target.value)
                }}
            >
                {
                    cities.map(d => {
                        return <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>
                    })
                }
            </Select>

            <br />
            <br />
            <Button variant="contained" color="primary" onClick={handleClick}>Speichern</Button>
        </div>
    )
}

export default Menu