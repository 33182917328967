import React, { useEffect, useState } from 'react';
import Announcement from './Announcement';
import Axios from 'axios';
import TimeCircular from './TimeCircular';

const Page2 = (props) => {
  let tvId = localStorage.getItem('location')
  const [files, setFiles] = useState([])

  useEffect(() => {
    getFiles()
  }, [props.data])

  const getFiles = async () => {

    let { data } = await Axios.get('https://admin.sigzentrum.ch/sig-tv/items/tvs_directus_files?filter[tvs_id][eq]=' + tvId)

    let urls = []

    for (let i = 0; i < data.data.length; i++) {
      let file = await Axios.get('https://admin.sigzentrum.ch/sig-tv/files/' + data.data[i].directus_files_id)
      urls.push(file.data.data.data.full_url)
    }
    setFiles(urls)
  }


  const [progress, setProgress] = useState(0)
  let seconds = props.data.data.home_time * 1000

  /* for linerprogress */
  let steps
  let current
  let _progress
  let currentSecond = 1


  useEffect(() => {
    /* define linearpgoress */
    steps = 100 / (seconds / 1000)
    current = steps
    _progress = steps

    const interval = setInterval(() => {

      /* change the page */
      if ((seconds / 1000) == currentSecond) {
        currentSecond = 1
        props.handleCount()
      }

      /* progress stuff */
      if (_progress === 100) {
        setProgress(steps)
        current = steps
        _progress = steps
      } else {
        setProgress(current + steps)
        current = current + steps
        _progress = _progress + steps
      }

      currentSecond++
    }, 1000)

    return () => {
      clearInterval(interval);
    }
  }, [])


  return (
    <div class="wrapper">
      <img className="page2-logo" alt="logo" src={props.logo}></img>
      <div className="announcement">
        <Announcement data={props.data} />
      </div>
      <div className="flyer1">
        <img src={files[0]}></img>
      </div>
      <div className="flyer2">
        <img src={files[1]}></img>
      </div>
      <div className="flyer3">
        <img src={files[2]}></img>
      </div>
      <div className="flyer4">
        <img src={files[3]}></img>
      </div>
      <div className="timerCircle">
        <TimeCircular counter={seconds / 1000} />
      </div>
    </div>
  );
}

export default Page2;