import { LinearProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import TimeCircular from './TimeCircular'

const MemberShipPayments = (props) => {
    /* for LinearProgress - start */
    const [progress, setProgress] = useState(0)
    let seconds = props.data.data.membership_time * 1000

    const [payments, setPayments] = useState()
    const [title, setTitle] = useState()
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [totalPage, setTotalPage] = useState()

    let pageSize = 20
    let secondrow = false
    let start = 0
    let end = pageSize
    let counter = 1
    let _totalPage
    let currrentPage = 1

    /* for linerprogress */
    let steps
    let current
    let _progress
    let currentSecond = 1

    useEffect(() => {
        /* define linearpgoress */
        steps = 100 / (seconds / 1000)
        current = steps
        _progress = steps

        if (props.data.data.memberpayments) {
            setData()
        } else {
            setLoading(false)
        }

        const interval = setInterval(() => {

            /* change the page */
            if((seconds / 1000) * _totalPage == currentSecond){
                currentSecond = 1
                props.handleCount()
            }
            
            /* if counter matches given seconds - slide to the next page */
            if(counter == seconds / 1000){
                setPage(currrentPage)
                currrentPage++
                counter = 1
            } else {
                counter++
            }

            /* progress stuff */
            if (_progress === 100) {
                setProgress(steps)
                current = steps
                _progress = steps
            } else {
                setProgress(current + steps)
                current = current + steps
                _progress = _progress + steps
            }

            currentSecond++            
        }, 1000)

        return () => {
            clearInterval(interval);
        }
    }, [])


    const setData = () => {
        let _data = props.data.data.memberpayments.split(',')

        let data = []

        for (let i = 0; i < _data.length; i++) {
            let newData = _data[i].split(';').slice(0, -1)
            data.push(newData)
        }

        setTitle(data[0])
        let paymentsData = data.splice(1)


        setTotalPage(Math.ceil(paymentsData.length / pageSize))
        _totalPage = Math.ceil(paymentsData.length / pageSize)

        let payments = []

        for (let i = 0; i < _totalPage; i++) {
            payments.push(paymentsData.slice(start, end))
            start = start + pageSize
            end = end + pageSize
        }

        setPayments(payments)
        setLoading(false)
    }


    if (loading) {
        return <LinearProgress />
    }

    return (
        <div className="content aidat">
            <h1>Aidat</h1>
            <h2>Mitgliedsbeiträge</h2>

            <br />

            {
                !props.data.data.memberpayments
                    ? 'Keine Mitgliedsbeiträge gefunden'
                    : (
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <th className={"align-left"}>{title[0]}</th>
                                        <th>{title[1]}</th>
                                        <th>{title[2]}</th>
                                        <th>{title[3]}</th>
                                        <th>{title[4]}</th>
                                        <th>{title[5]}</th>
                                        <th>{title[6]}</th>
                                        <th>{title[7]}</th>
                                        <th>{title[8]}</th>
                                        <th>{title[9]}</th>
                                        <th>{title[10]}</th>
                                        <th>{title[11]}</th>
                                        <th>{title[12]}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        payments[page].map((row, i) => {
                                            secondrow = !secondrow
                                            return (
                                                <tr key={i} className={secondrow ? "secondrow" : ""}>
                                                    <td className={"align-left"}>{row[0]}</td>
                                                    <td>{row[1]}</td>
                                                    <td>{row[2]}</td>
                                                    <td>{row[3]}</td>
                                                    <td>{row[4]}</td>
                                                    <td>{row[5]}</td>
                                                    <td>{row[6]}</td>
                                                    <td>{row[7]}</td>
                                                    <td>{row[8]}</td>
                                                    <td>{row[9]}</td>
                                                    <td>{row[10]}</td>
                                                    <td>{row[11]}</td>
                                                    <td>{row[12]}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <LinearProgress variant="determinate" value={progress} />
                            <br />

                            <b><p>Toplam {totalPage} sayfa içerisinde {page + 1}. sayfayı görmektesiniz.</p></b>
                            <p>Seite {page + 1} von {totalPage}</p>
                        </div>
                    )
            }

            <div className="timerCircle">
                <TimeCircular counter={(seconds / 1000) * totalPage} />
            </div>
        </div>

    )
}

export default MemberShipPayments


/*
<LinearProgress variant="determinate" value={progress} />
*/