import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser';
import Axios from 'axios';

const Flyer = (props) => {
    const [data, setData] = useState(props.data.data.lesson)
    const [file, setFile] = useState()

    useEffect(() => {
        getFiles()
    }, [props.data])


    const getFiles = async () => {
        let _file = await Axios.get('https://admin.sigzentrum.ch/sig-tv/files/' + data)
        console.log('asdf',_file)
        setFile(_file.data.data.data.full_url)
        console.log(file)
    }

    return (
        <div style={{textAlign: 'center'}}>
                {
                    data
                    ? <img style={{width: 'auto', maxHeight: '45vh'}} src={file}></img>
                    : ''
                }
        </div>
    )
}

export default Flyer