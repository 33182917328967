import React, { useEffect, useState } from 'react'
import imgImsakDark from '../assets/images/prayertime/imsak-dark.png'
import imgGunesDark from '../assets/images/prayertime/gunes-dark.png'
import imgOgleDark from '../assets/images/prayertime/ogle-dark.png'
import imgIkindiDark from '../assets/images/prayertime/ikindi-dark.png'
import imgAksamDark from '../assets/images/prayertime/aksam-dark.png'
import imgYatsiDark from '../assets/images/prayertime/yatsi-dark.png'

import imgImsakLight from '../assets/images/prayertime/imsak-light.png'
import imgGunesLight from '../assets/images/prayertime/gunes-light.png'
import imgOgleLight from '../assets/images/prayertime/ogle-light.png'
import imgIkindiLight from '../assets/images/prayertime/ikindi-light.png'
import imgAksamLight from '../assets/images/prayertime/aksam-light.png'
import imgYatsiLight from '../assets/images/prayertime/yatsi-light.png'

import momment from 'moment'
import Axios from 'axios'
import { LinearProgress } from '@material-ui/core'

const Flyer = (props) => {
    let color = "#eaeef1"
    const city = localStorage.getItem('prayerTimeCity')

    let _times

    const [loading, setLoading] = useState(true)
    const [times, setTimes] = useState()
    const [isImsak, setImsak] = useState(false)
    const [isGunes, setGunes] = useState(false)
    const [isOgle, setOgle] = useState(false)
    const [isIkindi, setIkindi] = useState(false)
    const [isAksam, setAksam] = useState(false)
    const [isYatsi, setYatsi] = useState(false)

    const getData = () => {
        let today = momment().format('DD.MM.yyyy')
        let url = "https://live.igmgapp.org:8091/api/Calendar/GetPrayerTimes?cityID=" + city + "&from=" + today

        Axios.get(url).then(res => {
            setTimes(res.data.list[0])
            setLoading(false)
            _times = res.data.list[0]
            startTimer()
        })
    }

    const getPrayTime = () => {
        let hours = new Date().getHours()
        let minutes = new Date().getMinutes()
        let now = (hours * 3600) + minutes * 60 + 1

        if (hours == '00' && minutes == '00') {
            getData()
        }

        let _fajr = (_times.fajr.split(':')[0] * 3600) + _times.fajr.split(':')[1] * 60 + 1
        let _sunrise = (_times.sunrise.split(':')[0] * 3600) + _times.sunrise.split(':')[1] * 60
        let _dhuhr = (_times.dhuhr.split(':')[0] * 3600) + _times.dhuhr.split(':')[1] * 60
        let _maghrib = (_times.maghrib.split(':')[0] * 3600) + _times.maghrib.split(':')[1] * 60
        let _asr = (_times.asr.split(':')[0] * 3600) + _times.asr.split(':')[1] * 60
        let _ishaa = (_times.ishaa.split(':')[0] * 3600) + _times.ishaa.split(':')[1] * 60

        if (now >= _fajr && now <= _sunrise) {
            setImsak(true)
            setGunes(false)
            setOgle(false)
            setIkindi(false)
            setAksam(false)
            setYatsi(false)
        } else if (now > _sunrise && now < _dhuhr) {
            setImsak(false)
            setGunes(true)
            setOgle(false)
            setIkindi(false)
            setAksam(false)
            setYatsi(false)
        } else if (now > _dhuhr && now < _asr) {
            setImsak(false)
            setGunes(false)
            setOgle(true)
            setIkindi(false)
            setAksam(false)
            setYatsi(false)
        } else if (now > _asr && now < _maghrib) {
            setImsak(false)
            setGunes(false)
            setOgle(false)
            setIkindi(true)
            setAksam(false)
            setYatsi(false)
        } else if (now > _maghrib && now < _ishaa) {
            setImsak(false)
            setGunes(false)
            setOgle(false)
            setIkindi(false)
            setAksam(true)
            setYatsi(false)
        } else {
            setImsak(false)
            setGunes(false)
            setOgle(false)
            setIkindi(false)
            setAksam(false)
            setYatsi(true)
        }
    }

    const startTimer = () => {
        setInterval(() => {
            getPrayTime()
        }, 1000)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div style={{ height: "100%" }}>
            {
                loading ? (
                    <LinearProgress />
                ) : (
                        <div style={{ backgroundColor: color }} className="prayertime">
                        
                            <div className="times">

                            <div className="prayertime-info">
                                {times.date + ' - ' + times.cityName}
                            </div>


                                <div className={isImsak ? "selected time" : "time"}>
                                    <div className="time-content">
                                        <p className="prayer-name">İmsak</p>
                                        <p className="prayer-time">{times.fajr}</p>
                                        <img src={isImsak ? imgImsakLight : imgImsakDark}></img>
                                    </div>
                                </div>
                                <div className={isGunes ? "selected time" : "time"}>
                                    <div className="time-content">
                                        <p className="prayer-name">Güneş</p>
                                        <p className="prayer-time">{times.sunrise}</p>
                                        <img src={isGunes ? imgGunesLight : imgGunesDark}></img>
                                    </div>

                                </div>
                                <div className={isOgle ? "selected time" : "time"}>
                                    <div className="time-content">
                                        <p className="prayer-name">Öğle</p>
                                        <p className="prayer-time">{times.dhuhr}</p>
                                        <img src={isOgle ? imgOgleLight : imgOgleDark}></img>
                                    </div>

                                </div>
                                <div className={isIkindi ? "selected time" : "time"}>
                                    <div className="time-content">
                                        <p className="prayer-name">İkindi</p>
                                        <p className="prayer-time">{times.asr}</p>
                                        <img src={isIkindi ? imgIkindiLight : imgIkindiDark}></img>
                                    </div>

                                </div>
                                <div className={isAksam ? "selected time" : "time"}>
                                    <div className="time-content">
                                        <p className="prayer-name">Akşam</p>
                                        <p className="prayer-time">{times.maghrib}</p>
                                        <img src={isAksam ? imgAksamLight : imgAksamDark}></img>
                                    </div>

                                </div>
                                <div className={isYatsi ? "selected time" : "time"}>
                                    <div className="time-content">
                                        <p className="prayer-name">Yatsı</p>
                                        <p className="prayer-time">{times.ishaa}</p>
                                        <img src={isYatsi ? imgYatsiLight : imgYatsiDark}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

export default Flyer