import React, { useEffect, useState } from 'react';
import MemberShipPayments from './MemberShipPayments';
import Lesson from './Lesson'
import Clock from './Clock'
import PrayerTime from './PrayerTime'
import DaysTopic from './DaysTopic'
import TimeCircular from './TimeCircular';
import Axios from 'axios';


const Page1 = (props) => {

    return (
        <div class="wrapper">
            <div className="membership">
                <MemberShipPayments data={props.data} handleCount={props.handleCount} />
            </div>
            <div className="prayertime">
                <PrayerTime data={props.data} />
            </div>
            <div className="clock-logo">
                <Clock />
                <img src={props.logo} style={{maxWidth: '70%'}}/>
            </div>
            <div className="info-page1">
                <Lesson data={props.data} />
            </div>
        </div>
    )
}

export default Page1;