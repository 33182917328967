import React from 'react'
import parse from 'html-react-parser';

const Announcement = (props) => {
    return (
        <div className="content">
            <h1>Duyurular</h1>
            <h2>Ankündigungen</h2>
            <br />
            {
                props.data.data.announcement ? (
                    parse(props.data.data.announcement)
                ) : (
                        <div></div>
                    )
            }
        </div>
    )
}

export default Announcement